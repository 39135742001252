import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import {
  testimony2Section,
  testimony2Box,
  imageBackdrop,
  imageWrap,
  image,
  messageWrap,
} from './testimony2.module.scss';

function Testimony2() {
  return (
    <section className={testimony2Section}>
      <h2>Check out what our customers are saying</h2>
      <div className={testimony2Box}>
        <div className={imageBackdrop}>
          <div className={imageWrap}>
            <StaticImage
              className={image}
              src='../../images/support.jpg'
              alt='testimony picture'
            />
          </div>
        </div>
        <div className={messageWrap}>
          <span className='medium-font-heavy'>
            {' '}
            A unique platform for your hospital that simplifies communication
            and makes scheduling easier than ever{' '}
          </span>
        </div>
      </div>
    </section>
  );
}

export default Testimony2;

import React from 'react';
import {
  supportSection,
  supportSketch,
  contactsWrap,
  contactTagText,
  contactText,
} from './support-section.module.scss';
import SupporSketch from '../icons/supporSketch';

function SupportSection() {
  return (
    <section className={supportSection}>
      <div>
        <SupporSketch className={supportSketch} />
      </div>
      <div className={contactsWrap}>
        <h2>How can we help you?</h2>
        <p className='medium-font-medium'>
          We provide 24/7 support to our customers. We will reach back to you as
          soon as we can.
        </p>
        <div>
          <span className={contactTagText}>Call us at: </span>
          <span className={contactText}>+91- 969-956-9785</span>
        </div>
        <div className={contactTagText}>or</div>
        <div>
          <span className={contactTagText}> Drop an email:</span>{' '}
          <span className={contactText}> support@dailydoc.io</span>
        </div>
      </div>
    </section>
  );
}

export default SupportSection;

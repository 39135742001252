import React from 'react';
import Layout from '../components/layout';
import SupportSection from '../components/support/SupportSection';
import Testimony2 from '../components/testimony/testimony2';
import GetFree from '../components/get-free/getFree';

const Support = () => {
  return (
    <Layout pageTitle='Support Page'>
      <SupportSection />
      <Testimony2 />
      <GetFree />
    </Layout>
  );
};

export default Support;

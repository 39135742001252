import React from 'react';
import { Link } from 'gatsby';
import { getFreeSection } from './getFree.module.scss';

function GetFree() {
  return (
    <section className={`bg-indigo ${getFreeSection}`}>
      <div className='large-container'>
        <h1>Get Daily Doc For Free</h1>
        <p className='medium-font-heavy'>Daily Doc is completely free.</p>
        <Link to='/contact' className='button button-green'>
          GET STARTED
        </Link>
      </div>
    </section>
  );
}

export default GetFree;
